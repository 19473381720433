body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
}

strong {
    font-weight: 500;
}
